import React, { lazy, Suspense, useEffect } from "react";
import "./styles/global.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Main/Footer";
import { Helmet } from "react-helmet-async";
import axios from "axios";

const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const Main = lazy(() => import("./pages/Main"));
const Navbar = lazy(() => import("./components/Header/Navbar"));
const NoticeList = lazy(() => import("./pages/Board/Notice/NoticeList"));
const InquireForm = lazy(() => import("./pages/Board/Inquire/InquireForm"));
const Faq = lazy(() => import("./pages/Board/Faq/Faq"));
const Broker = lazy(() => import("./pages/Service/Broker/Broker"));
const Management = lazy(() => import("./pages/Service/Management/Management"));
const KakaoMap = lazy(() => import("./pages/Map/KakaoMap"));
const Mypage = lazy(() => import("./pages/Mypage/Mypage"));
const Service = lazy(() => import("./pages/Service/Service"));
const Require = lazy(() => import("./pages/Service/Require/Require"));
const Sell = lazy(() => import("./pages/Service/Sell/Sell"));
const DoongjiMap = lazy(() => import("./pages/Doongji/DoongjiMap"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Kakao = lazy(() => import("./pages/Login/Kakao"));
const Google = lazy(() => import("./pages/Login/Google"));
const FindPassword = lazy(() => import("./pages/Login/FindPassword"));
const TermsService = lazy(() => import("./pages/Terms/Service"));
const TermsPrivacy = lazy(() => import("./pages/Terms/Privacy"));

function App() {
  return (
    <Suspense>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/map" element={<KakaoMap />} />
          <Route path="/board/notice" element={<NoticeList />} />
          <Route path="/board/inquire" element={<InquireForm />} />
          <Route path="/board/faq" element={<Faq />} />
          <Route path="/broker" element={<Broker />} />
          <Route path="/service/management" element={<Management />} />
          <Route path="/mypage" element={<Mypage />} />
          <Route path="/service" element={<Service />} />
          <Route path="/service/require" element={<Require />} />
          <Route path="/service/sell" element={<Sell />} />
          <Route path="/doongji/map" element={<DoongjiMap />} />
          <Route path="/kakao/callback/*" element={<Kakao />} />
          <Route path="/google/callback/*" element={<Google />} />
          <Route path="/login/findpassword" element={<FindPassword />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/api/*" element={<NotFound />} />
          <Route path="/terms/service" element={<TermsService />} />
          <Route path="/terms/privacy" element={<TermsPrivacy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
